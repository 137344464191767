import React from "react";
import "./App.css";
import { BrowserRouter, Switch, Routes, Route } from "react-router-dom";

import Home from "./component/hp";

import ForgotPassword from "./component/ForgotPassword";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import Academy from "./component/Newhp";
import Skillnew from "./component/Newskill";
import Technologies from "./component/Technology";
import Corp from "./component/Corp";
import Newcontact from "./component/Newcontact";
import About from "./component/About";
import Register from "./component/Register";
import Terms from "./component/Terms";
import Devops from "./component/Devops";
import Fullstack from "./component/Fullstack";
import AI from "./component/AI3month";
import Devops3month from "./component/Devops3month";
import Devops4week from "./component/Devops4week";
import AI1hour from "./component/AI1hour";
import AI4week from "./component/AI4week";
import Fullstack1hour from "./component/Fullstack1hour";
import Fullstack4week from "./component/Fullstack4week";
import Iot3month from "./component/Iot3month";
import Iot4week from "./component/Iot4week";
import Iot1hour from "./component/Iot1hour";
import Datascience1hour from "./component/Datascience1hour";
import Datascience4week from "./component/Datascience4week";
import Datascience3month from "./component/Datascience3month";
import Termscondt from "./component/Termscondt1";
// Declare dataLayer variable
var dataLayer = window.dataLayer || [];

// Google Analytics tracking code
function gtag() {
  dataLayer.push(arguments);
}
gtag("js", new Date());
gtag("config", "G-PR1S1HP8BZ");
function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<Academy />} />
        <Route exact path="Skill" element={<Skillnew />} />
        <Route exact path="Corporates" element={<Corp />} />
        <Route exact path="Register" element={<Register />} />
        <Route exact path="ContactUs" element={<Newcontact />} />
        <Route exact path="devops" element={<Devops />} />
        <Route exact path="devops3month" element={<Devops3month />} />
        <Route exact path="devops4week" element={<Devops4week />} />
        <Route exact path="datascience1hour" element={<Datascience1hour />} />
        <Route exact path="datascience4week" element={<Datascience4week />} />
        <Route exact path="datascience3month" element={<Datascience3month />} />
        <Route exact path="termsandconditions" element={<Termscondt />} />

        <Route exact path="fullstack" element={<Fullstack />} />
        <Route exact path="fullstack1hour" element={<Fullstack1hour />} />
        <Route exact path="fullstack4week" element={<Fullstack4week />} />
        <Route exact path="Iot3month" element={<Iot3month />} />
        <Route exact path="Iot4week" element={<Iot4week />} />
        <Route exact path="Iot1hour" element={<Iot1hour />} />
        <Route exact path="AI" element={<AI />} />
        <Route exact path="AI1hour" element={<AI1hour />} />
        <Route exact path="AI4week" element={<AI4week />} />
        <Route exact path="about" element={<About />} />
        <Route exact path="technologies" element={<Technologies />} />
        <Route exact path="terms" element={<Terms />} />
        <Route exact path="ForgotPassword" element={<ForgotPassword />} />
      </Routes>
    </div>
  );
}

export default App;
