import React from "react";
import Navbar from "./Navbar.js";
import "../styles/corporate.css";
import Footer from "./footer.js";
import "../styles/footer.css";
import "../styles/navbar.css";
import "../styles/Termscondt.css"
import image1 from "../images/corp-img1.png";
import image2 from "../images/product1.png";
import image3 from "../images/product2.png";
import image4 from "../images/product3.png";
import image5 from "../images/product4.png";
import image6 from "../images/product5.png";
import image7 from "../images/languagesimg.png";
import image8 from "../images/mentor.png";
import image9 from "../images/tv.png";
import image10 from "../images/bulb.png";
import image11 from "../images/jobsearch.png";
import image12 from "../images/human-brain.png";
import image13 from "../images/scorecard.png";
import image14 from "../images/interview.png";
import image15 from "../images/intern.png";
import image16 from "../images/calender.png";
import { Row, Col } from "react-bootstrap";

function Termscondt() {
  return (
    <div>
      <Navbar />
      <br /><br /><br /><br /><br />
      <div>
        <Row className="termspage">
          <Row>
            <p style={{ color: "black", fontSize: "36px", fontFamily: "sans-serif", fontWeight: "bold", }}>Terms and Conditions</p>
          </Row>
          <Row>
            <p style={{ color: "black", fontSize: "16px", fontFamily: "sans-serif" }}>Welcome to KambGeeks! These Terms and Conditions govern your use of our services, products, and website (collectively referred to as "Services"). By accessing or using our Services, you agree to be bound by these Terms and Conditions. If you disagree with any part of these terms, please discontinue use of our Services.</p>
          </Row>
          <Row>
            <p style={{ color: "black", fontSize: "26px", fontFamily: "sans-serif", fontWeight: "bold", }}>1. Definitions</p>
          </Row>
          <Row>
            <p style={{ color: "black", fontSize: "16px", fontFamily: "sans-serif" }}><ul><li>"Company" refers to KambGeeks.</li>
              <li>"User/You" refers to any individual or entity using our Services.</li>
              <li>"Services" includes our software, website, mobile applications, and associated offerings.</li></ul></p>
          </Row>
          <Row>
            <p style={{ color: "black", fontSize: "26px", fontFamily: "sans-serif", fontWeight: "bold", }}>2. Eligibility</p>
          </Row>
          <Row>
            <p style={{ color: "black", fontSize: "16px", fontFamily: "sans-serif" }}>You must be at least 18 years old to use our Services. By using our Services, you represent that you meet this eligibility requirement.</p>
          </Row>
          <Row>
            <p style={{ color: "black", fontSize: "26px", fontFamily: "sans-serif", fontWeight: "bold", }}>3. Use of Services</p>
          </Row>
          <Row>
            <p style={{ color: "black", fontSize: "16px", fontFamily: "sans-serif" }}><ul><li>You agree to use our Services only for lawful purposes and in compliance with these Terms.</li >
              <li>Unauthorized or malicious use of our Services, including attempts to disrupt, reverse-engineer, or compromise security, is prohibited.</li></ul></p>
          </Row>
          <Row>
            <p style={{ color: "black", fontSize: "26px", fontFamily: "sans-serif", fontWeight: "bold", }}>4. Intellectual Property</p>
          </Row>
          <Row>
            <p style={{ color: "black", fontSize: "16px", fontFamily: "sans-serif" }}><ul><li>All content, designs, and software provided through KambGeeks are the intellectual property of the Company or its licensors.</li >
            <li >Users are not permitted to copy, modify, or distribute any materials without prior written permission.</li></ul></p>
          </Row>
          <Row>
            <p style={{ color: "black", fontSize: "26px", fontFamily: "sans-serif", fontWeight: "bold", }}>5. User Data</p>
          </Row>
          <Row>
            <p style={{ color: "black", fontSize: "16px", fontFamily: "sans-serif" }}><ul><li>We value your privacy. By using our Services, you consent to the collection and use of your information as outlined in our Privacy Policy.</li >
            <li >You are responsible for maintaining the confidentiality of your account credentials.</li></ul></p>
          </Row>
          <Row>
            <p style={{ color: "black", fontSize: "26px", fontFamily: "sans-serif", fontWeight: "bold", }}>6. Payment Terms</p>
          </Row>
          <Row>
            <p style={{ color: "black", fontSize: "16px", fontFamily: "sans-serif" }}><ul><li>For any paid services, payment must be made in accordance with the agreed pricing and terms.</li >
            <li >Failure to make timely payments may result in suspension or termination of access to Services.</li></ul></p>
          </Row>
          <Row>
            <p style={{ color: "black", fontSize: "26px", fontFamily: "sans-serif", fontWeight: "bold", }}>7. Limitation of Liability</p>
          </Row>
          <Row>
            <p style={{ color: "black", fontSize: "16px", fontFamily: "sans-serif" }}>
              <ul>
                <li>
                KambGeeks is not liable for any direct, indirect, incidental, or consequential damages arising from the use or inability to use our Services.</li>
                <li>
                Our Services are provided "as-is" without warranties of any kind, unless explicitly stated otherwise.</li>
              </ul></p>
          </Row>
          <Row>
            <p style={{ color: "black", fontSize: "26px", fontFamily: "sans-serif", fontWeight: "bold", }}>8. Termination</p>
          </Row>
          <Row>
            <p style={{ color: "black", fontSize: "16px", fontFamily: "sans-serif" }}>We reserve the right to suspend or terminate your access to our Services at our sole discretion, with or without notice, for any violation of these Terms.</p>
          </Row>
          <Row>
            <p style={{ color: "black", fontSize: "26px", fontFamily: "sans-serif", fontWeight: "bold", }}>9. Governing Law</p>
          </Row>
          <Row>
            <p style={{ color: "black", fontSize: "16px", fontFamily: "sans-serif" }}>These Terms are governed by the laws of Tamil Nadu, India. Any disputes arising from or related to these Terms will be resolved exclusively in the courts of Tamil Nadu, India.</p>
          </Row>
          <Row>
            <p style={{ color: "black", fontSize: "26px", fontFamily: "sans-serif", fontWeight: "bold", }}>10. Changes to Terms</p>
          </Row>
          <Row>
            <p style={{ color: "black", fontSize: "16px", fontFamily: "sans-serif" }}>We reserve the right to modify these Terms at any time. Changes will be communicated via our website or email. Continued use of our Services after changes constitutes acceptance of the new Terms.</p>
          </Row>
          <Row>
            <p style={{ color: "black", fontSize: "26px", fontFamily: "sans-serif", fontWeight: "bold", }}>11. Contact Us</p>
          </Row>
          <Row>
            <p style={{ color: "black", fontSize: "16px", fontFamily: "sans-serif" }}>For questions or concerns about these Terms, contact us at:<br />
              Email: support@kambgeeks.com<br />
              Phone: 04435924999</p>
          </Row>
        </Row>
      </div>
      <Footer />
    </div>
  );
}

export default Termscondt;
