// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @media only screen and (min-width: 1024px) and (max-width: 1440px) { */
    .termspage{
        margin-left: 20%;
        margin-right: 20%;
    }
/* } */
@media only screen and (min-width: 335px) and (max-width: 990px) {
    .termspage{
        margin-left: 5%;
        margin-right: 5%;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/Termscondt.css"],"names":[],"mappings":"AAAA,yEAAyE;IACrE;QACI,gBAAgB;QAChB,iBAAiB;IACrB;AACJ,MAAM;AACN;IACI;QACI,eAAe;QACf,gBAAgB;IACpB;AACJ","sourcesContent":["/* @media only screen and (min-width: 1024px) and (max-width: 1440px) { */\n    .termspage{\n        margin-left: 20%;\n        margin-right: 20%;\n    }\n/* } */\n@media only screen and (min-width: 335px) and (max-width: 990px) {\n    .termspage{\n        margin-left: 5%;\n        margin-right: 5%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
